import React from 'react'
import { useTranslation } from 'react-i18next'
import Fade from 'react-reveal/Fade'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageIntro from '../components/pageIntro/pageIntro'

function Anafi() {
    const { t } = useTranslation('anafi')
    return (
        <Layout darkHeader>
            <SEO title={t('title')}
                description={t('seoDescription')}
                image="../assets/images/anafi.jpg"
                alreadyTranslated />
            <PageIntro
                title={t('headerTitle')}
                subtitle={t('headerSubtitle')}
            />
            <Fade right duration={1000} delay={400} distance="10px">
                <StaticImage
                    src="../assets/images/anafi.jpg"
                    layout="fullWidth"
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Αρμενάκι κατοικίες κεντρική"
                />
            </Fade>
            <Container className="mt-5 mb-5">
                <Fade bottom duration={1000} delay={400} distance="10px">
                    <p className="mt-2">{t('paragraph1')}</p>
                </Fade>
                <Fade bottom duration={1000} delay={400} distance="10px">
                    <p className="mt-2">{t('paragraph2')}</p>
                </Fade>
                <Fade bottom duration={1000} delay={400} distance="10px">
                    <p className="mt-2">{t('paragraph3')}</p>
                </Fade>
                <Fade bottom duration={1000} delay={400} distance="10px">
                    <p className="mt-2">
                        {t('paragraph4')}
                    </p>
                </Fade>
            </Container>
        </Layout>
    )
}

export default Anafi
